.navi-box {
    background-color: var(--color-main-bg);
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
    color: var(--color-main-text);
}

.nav-text a:hover,
.nav-text.active a:hover {
    background-color: var(--color-main-ancient);
    color: var(--color-main-bg);
}
.nav-text a:hover div div svg,
.nav-text.active a:hover div div svg {
    color: var(--color-main-bg);
}

.nav-menu-items {
    color: var(--color-main-text);
    width: 100%;
    overflow-y: auto;
}

.nav-text.active > a {
    text-decoration: underline;
    text-decoration-color: var(--color-main-ancient);
    color: var(--color-main-ancient);
}
.nav-text.active > a > div > div > svg {
    color: var(--color-main-ancient);
}
