@import url('bootstrap/dist/css/bootstrap.min.css');
:root {
  --color-main-bg: #1b1b27; 
  --color-main-bg-2: #34344d; 
  --color-main-text: #cfcfec; 
  --color-main-ancient: #e2d9a3;
}
/* Toastr */
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #0f0f16;
  --toastify-color-info: #3434b9;
  --toastify-color-success: #50d454;
  --toastify-color-warning: #f1a20f;
  --toastify-color-error: #d33f2f;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: inherit;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: var(--color-main-text);

  /* //Used only for colored theme */
  --toastify-text-color-info: var(--color-main-text);
  --toastify-text-color-success: var(--color-main-text);
  --toastify-text-color-warning: var(--color-main-text);
  --toastify-text-color-error: var(--color-main-text);

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* // Used when no type is provided
  // toast("**hello**") */
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  /* // Used when no type is provided */
  --toastify-color-progress-dark: var(--color-main-ancient);
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
/* end toastr */

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px var(--color-main-ancient);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-main-ancient);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(54, 56, 58);
}
body {
  background-color: var(--color-main-bg-2);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  background-color: #282c34;
  color: aliceblue;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardSelected {
  border: 2px solid var(--color-main-ancient);
}