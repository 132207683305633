.global-loading-screen {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: var(--color-main-bg);
    fill: var(--color-main-ancient);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .9;
}

.global-loading-screen .global-loading-animation {
    display: 'flex'; 
    align-items: 'center'; 
    justify-content: 'center';

}